.collapse-filter {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
}

.collapse-filter .ant-btn-link {
  display: none;
  padding: 0;
}

.collapse-filter:hover .ant-btn-link {
  display: inline;
}

.collapse-filter.excluded {
  text-decoration: line-through;
  color: #777777;
}

.Span-Result {
  margin-left: 5px;
  color: #888;
}

.custom-modal-width {
  max-width: 100%;
  width: 100%;
}

.par {
  font-size: 12px;
  margin: 0;
}

.div-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.bullet {
  margin: 0;
}

.back-button {
  margin-left: 95%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.filter-item {
  padding: 8px;
  transition: background-color 0.2s;
}

.filter-item:hover {
  background-color: #f5f5f5;
}

.filter-item .ant-checkbox-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.location-filter {
  max-height: 300px;
  overflow-y: auto;
}

.location-search {
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.search-results-card {
  position: relative;
}

.filters-section {
  transition: opacity 0.2s;
}

.filters-section.filters-loading {
  pointer-events: none;
}

.loading-counts {
  padding: 8px;
}

.results-section {
  position: relative;
  min-height: 200px;
}

.location-filter .ant-skeleton {
  margin: 8px;
}

.loading-state {
  padding: 24px;
  background: white;
  border-radius: 4px;
}

.filters-section .ant-collapse {
  background: transparent;
}

.filters-section .ant-collapse-item {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.count-badge {
  background: #1890ff;
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
}

.ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ant-radio-wrapper {
  margin-right: 0 !important;
}

.loading-results {
  min-height: 200px;
  padding: 16px;
}

.filter-loading {
  opacity: 0.7;
  pointer-events: none;
}

.location-filter-loading {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: #fafafa;
  border-radius: 4px;
}

.keyword-preview {
  min-height: 32px;
  padding: 4px 11px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 4px;
}

.keyword-operator {
  font-style: italic;
  opacity: 0.7;
}

.keyword-input {
  font-family: monospace;
}

.keyword-input :global(.ant-input) {
  font-family: inherit;
}

.keyword-input :global(.boolean-operator) {
  font-style: italic;
  opacity: 0.7;
  color: #1890ff;
}

/* Add styles for matching operators */
.keyword-input :global([data-operator]) {
  font-weight: 500;
  color: #1890ff;
}

.keyword-input :global([data-operator="AND"]) {
  color: #52c41a;
}

.keyword-input :global([data-operator="OR"]) {
  color: #faad14;
}

.keyword-input :global([data-operator="NOT"]) {
  color: #ff4d4f;
}
