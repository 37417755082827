.dashboard-header {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  background: linear-gradient(to right, #f7faff, #ffffff);
}

.dashboard-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  /* height: 100%; */
}

.dashboard-card .ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.dashboard-card .ant-card-head-title {
  font-weight: 600;
}

.dashboard-card .ant-card-body {
  padding: 24px;
}

/* Stat Card Styles */
.stat-card {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  height: 100%;
  transition: all 0.3s;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.stat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  font-size: 20px;
  margin-right: 16px;
}

.stat-icon .anticon {
  color: inherit;
}

.stat-content {
  flex: 1;
}

/* Timeline Styles */
.timeline-card {
  max-height: 700px;
}

.timeline-container {
  max-height: 600px;
  overflow-y: auto;
  padding: 8px 0;
}

.timeline-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 16px;
  border: 2px solid;
}

.timeline-item {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 8px;
}

.timeline-meta {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.timeline-meta .ant-tag {
  margin-right: 8px;
}

.load-more-container {
  margin-top: 16px;
  text-align: center;
  padding: 0 16px;
}

/* Boolean Strings Styles */
.boolean-strings-container {
  max-height: 400px;
  overflow-y: auto;
}

.boolean-string-item {
  display: flex;
  margin-bottom: 16px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 16px;
}

.boolean-string-item:last-child {
  margin-bottom: 0;
}

.boolean-string-item .ant-typography-code {
  background-color: #1890ff;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 12px;
  font-size: 14px;
}

.boolean-string-content {
  flex: 1;
  padding: 2px 0;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .stat-card {
    padding: 12px;
  }

  .stat-icon {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .dashboard-card .ant-card-body {
    padding: 16px;
  }
}
