.public-job-openings {
  display: flex;
  gap: 20px;
  &-sidebar {
    flex: 0.3;
    height: calc(100vh - (64px + 47px));
    overflow-y: auto;
    padding-top: 40px;
    min-width: 300px;
    .search-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
      padding: 0 24px;
      input {
        flex: 1;
        background: #f2f1f9;
        border: 0;
        padding: 1rem;
        outline-color: #40a9ff;
        border-radius: 5px;
      }
    }
    .filter-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .right-container {
    padding-top: 20px;
    padding-bottom: 80px;
    flex: 1;
    height: calc(100vh - (64px + 50px));
    overflow-y: auto;
    .job-listing {
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .job-card {
        width: 100%;
        p {
          word-wrap: break-word;
        }
      }
    }
    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .empty-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

@media (max-width: 768px) {
  .public-job-openings {
    flex-direction: column;
    &-sidebar {
      flex: 1;
      min-width: 100%;
      .filter-container {
        flex-direction: row;
        flex-wrap: wrap;
        > div {
          flex: 1;
          min-width: 200px;
        }
      }
    }
    .right-container {
      .job-listing {
        width: 100%;
      }
    }
  }
}

.quick-apply-modal {
  .ant-modal-close {
    right: unset;
    left: 0;
  }
  .ant-modal-title {
    padding-left: 35px;
  }
}
