
  .output-title {
      width: 100%;
      background-color: #202123;
      color: white;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      /* Use flexbox for layout */
      justify-content: space-between;
      /* Space between title and button */
      align-items: center;
      /* Vertically center align items */
      box-sizing: border-box;
    }
   #outputBox {
      width: 100%;
      height: 300px;
      background-color: #000000;
      /* Dark background for the output */
      color: white;
      /* White text color */
      border: none;
      /* No border */
      padding: 16px;
      box-sizing: border-box;
      overflow-y: auto;
      /* Scrollbar if content overflows */
      font-family: "Söhne Mono", "Monaco", "Andale Mono", "Ubuntu Mono", monospace !important;
      /* Code-like font */

      font-size: 14px;
      margin-top: -4px;
    }

    #outputBox:focus {
      outline: none;
      /* Removes the default focus outline */
    }

     #generateSampleData:hover {
      background-color: #0056b3;
      /* Darker Blue */
    }

    .regenerate-section {
      margin-bottom: 10px;
      flex-direction: column;
      /* Initially hidden */
      /* ... other styles ... */
    }



    .regenerate-controls {
      display: flex;
      align-items: center;
    }
.hidden {
  display: none;
}

.visible {
  display: block;
}