.jobSearchInputContainer {
  ::-webkit-scrollbar {
    display: none;
  }
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: transparent;
  padding: 32px;
  .ant-select-selection-placeholder {
    right: unset !important;
  }
}

@media (max-width: 768px) {
  .jobSearchInputContainer {
    flex-direction: column;
  }
}
