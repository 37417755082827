* {
  word-wrap: break-word;
}

.v2_dasboard_container {
  &:not(.p-0) {
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      transition: all 0.3s ease;
    }
  }
  padding-top: 25px;
  ul {
    list-style: none;
  }
  &_chart_container {
    display: flex;
    gap: 2rem;
    flex-direction: column-reverse;
    .left_container,
    .right_container {
      width: 100%;
    }
    .big_chart {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
      border-radius: 12px;
      background-color: #fff;
      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        h3 {
          font-size: 1.8rem;
          font-weight: 500;
        }
        .custom_legend {
          display: flex;
          gap: 1rem;
          align-items: center;

          .legend_item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            span {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            p {
              font-size: 1rem;
              font-weight: 400;
            }
          }
        }
      }
      .chandidateChartDiv {
        height: 350px;
      }
    }

    .dashboard_statistics_count_container {
      display: flex;
      gap: 2rem;
      .dashboard_statistics_count {
        flex: 1;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 1.5rem;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        .titleContainer {
          display: flex;
          align-items: center;
          flex: 0.3;
          gap: 1rem;
          img {
            flex-shrink: 0;
          }
          h4 {
            font-size: 1.5rem;
            font-weight: 500;
          }
        }
        .counter_div {
          .counter {
            font-size: 3rem;
            font-weight: 300;
            color: #6993ff;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .card-custom {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .card-header {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f9f9f9;
      .card-title {
        font-size: 1.8rem;
        font-weight: 500;
      }

      .post-job-btn {
        background-color: #007bff;
        padding: 6px 12px;
        border-radius: 6px;
        &:hover {
          background-color: #0056b3;
        }
      }
    }

    .card-body {
      padding: 0;
      .table-container {
        overflow-x: auto;

        .styled-table {
          width: 100%;
          border-collapse: collapse;
          th {
            text-align: left;
            padding: 12px 15px;
            font-size: 1rem;
            color: #555555;
            background-color: #f9f9f9;
            border-bottom: 1px solid #eaeaea;
            white-space: nowrap;
            font-weight: 400;
          }

          td {
            padding: 12px 15px;
            font-size: 1rem;
            color: #1b1b1b;
            border-bottom: 1px solid #eaeaea;
          }

          .candidate-row {
            &:hover {
              background-color: #f3f3f3;
            }
          }

          .candidate-info {
            display: flex;
            align-items: center;

            .candidate-details {
              display: flex;
              align-items: center;
            }

            .candidate-avatar {
              margin-right: 10px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #f0f0f0;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
          .candidate-name {
            text-decoration: none;
            color: #1b1b1b;
            white-space: nowrap;

            &:hover {
              text-decoration: underline;
            }
          }

          .candidate-job-title {
            min-width: 200px;
          }
          .candidate-job-description {
            min-width: 200px;
            line-clamp: 1;
            -webkit-line-clamp: 1;
          }
          .candidate-ctc,
          .candidate-location {
            text-align: center;
          }

          .candidate-status {
            text-align: center;

            .status-badge {
              display: inline-block;
              padding: 4px 10px;
              border-radius: 12px;
              font-size: 0.9rem;
              font-weight: 500;
              text-transform: capitalize;
              color: #ffffff;

              &.default {
                background-color: #b0b0b0;
              }

              &.active {
                background-color: #28a745;
              }

              &.inactive {
                background-color: #dc3545;
              }

              &.pending {
                background-color: #ffc107;
              }
            }
          }

          .candidate-actions {
            text-align: right;

            .view-profile-btn {
              background-color: #28a745;

              border: none;
              font-weight: 500;
              border-radius: 6px;
              padding: 6px 12px;
              &:hover {
                background-color: #218838;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .v2_dasboard_container {
    &_chart_container {
      gap: 1rem;
      .dashboard_statistics_count_container {
        gap: 1rem;
        .dashboard_statistics_count {
          .titleContainer {
            h4 {
              font-size: 1.2rem;
            }
          }
          .counter_div {
            .counter {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .v2_dasboard_container {
    &_chart_container {
      gap: 2rem;

      .dashboard_statistics_count_container {
        flex-wrap: wrap;
        gap: 1rem;
        .dashboard_statistics_count {
          .titleContainer {
            h4 {
              font-size: 1.2rem;
            }
          }
          .counter_div {
            .counter {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

//  response for mobile

@media screen and (max-width: 525px) {
  .v2_dasboard_container {
    &_chart_container {
      .big_chart {
        padding: 1rem;
        &_header {
          margin-bottom: 1rem;
          h3 {
            font-size: 1.5rem;
          }
          .custom_legend {
            .legend_item {
              span {
                width: 15px;
                height: 15px;
              }
              p {
                font-size: 0.8rem;
              }
            }
          }
        }
        .chandidateChartDiv {
          height: 300px;
        }
      }
      .dashboard_statistics_count_container {
        .dashboard_statistics_count {
          width: 100%;
          .titleContainer {
            h4 {
              font-size: 1rem;
            }
          }
          .counter_div {
            .counter {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
