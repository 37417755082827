$openings-primary-color: #1890ff;
$openings-secondary-color: #52c41a;
$openings-accent-color: #722ed1;
$openings-text-color: #1b1b1b;
$openings-border-color: #e8e8e8;
.applicants-table {
  .ant-table-thead {
    .ant-table-cell {
      white-space: nowrap;
    }
  }
  .applicant-row {
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(24, 144, 255, 0.05);
    }
  }

  .ant-table-cell {
    padding: 12px 16px;
    vertical-align: middle;
  }
  .candidate-details {
    display: flex;
    align-items: center;
    gap: 10px;
    .candidate-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f0f0f0;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      align-items: center;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.job-openings-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  .job-openings-table {
    .ant-table-thead {
      .ant-table-cell {
        white-space: nowrap;
      }
    }
    .job-openings-row {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(24, 144, 255, 0.05);
      }
    }

    .ant-table-cell {
      padding: 12px 16px;
      vertical-align: middle;
    }
  }

  .status-select,
  .status-filter {
    min-width: 150px;

    .ant-select-selector {
      border-radius: 4px;
    }
  }

  .search-input {
    max-width: 250px;
    border-radius: 4px;

    .ant-input-prefix {
      color: $openings-primary-color;
    }
  }

  .page-size-input {
    width: 100px;

    .ant-input-number-input {
      text-align: center;
    }
  }

  .table-controls {
    margin-bottom: 16px;

    .ant-col {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .job-openings-table {
      .ant-table-content {
        overflow-x: auto;
      }
    }

    .table-controls {
      flex-direction: column;

      .ant-col {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }

  .opening-title {
    color: $openings-primary-color;
    font-weight: 600;
    transition: color 0.3s ease;

    &:hover {
      color: darken($openings-primary-color, 10%);
      text-decoration: underline;
    }
  }

  .skills-cell {
    color: $openings-text-color;
    margin-bottom: 0;
  }
}
.action-btn {
  margin: 0 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.3s ease;

  &.notes-btn {
    color: $openings-accent-color;
    &:hover {
      background-color: rgba($openings-accent-color, 0.1);
    }
  }

  &.view-btn {
    color: $openings-primary-color;
    background-color: white;
    &:hover {
      color: white;
      background-color: $openings-primary-color;
    }
  }

  &.edit-btn {
    color: $openings-secondary-color;
    background-color: white;
    &:hover {
      color: white;
      background-color: $openings-secondary-color;
    }
  }

  &.delete-btn {
    background-color: white;
    color: #ff4d4f;
    &:hover {
      color: white;
      background-color: #ff4d4f;
    }
  }
}
// Add/New Opening Button
.add-opening-btn {
  background-color: $openings-secondary-color;
  border-color: $openings-secondary-color;

  &:hover {
    background-color: darken($openings-secondary-color, 10%);
    border-color: darken($openings-secondary-color, 10%);
  }
}
